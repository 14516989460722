<template>
  <div class="home">
    <div class="container">
      <div class="is-flex-desktop is-block-mobile">
        <div class="is-flex-direction-column">
          <a href="https://lazboyasiaconf.com/virtualgallery">
            <figure class="image">
              <img :src="require('@/assets/products/virtual-gallery.png')" />
            </figure>
          </a>
          <router-link to="/catalog">
            <figure class="image mt-5">
              <img :src="require('@/assets/products/catalog.jpg')" />
            </figure>
          </router-link>
        </div>
      </div>
      <div class="columns mt-3">
        <div class="column">
          <video
            controls
            :src="'/videos/beyond-the-recliner-collection.mp4'"
            :poster="require('@/assets/products/beyond-collection.png')"
          ></video>
        </div>
        <div
          class="column"
          v-bind:class="{
            'fa-caret-down': !expandVirtualGallery,
            'fa-caret-right': expandVirtualGallery,
          }"
        >
          <video
            controls
            :src="'/videos/La-Z-Boy-cat-pos.mp4'"
            :poster="require('@/assets/products/cat-pos.png')"
          ></video>
        </div>
      </div>
      <div class="bg-blue">
        <div class="virtual-gallery-2020-container">
          <h6 class="pointer  text-h6 mb-4" @click="toggleVirtualGallery()">
            La-Z-Boy Virtual Gallery 2020
            <v-icon color="black" v-show="!expandVirtualGallery"
              >mdi-menu-down</v-icon
            >
            <v-icon color="black" v-show="expandVirtualGallery"
              >mdi-menu-up</v-icon
            >
          </h6>
          <v-expand-transition>
            <v-card v-show="expandVirtualGallery" width="100%">
              <a href="https://lazboyasiaconf.com/product/virtualgallery">
                <figure class="image">
                  <img
                    :src="require('@/assets/products/virtual-gallery-2020.jpg')"
                  />
                </figure>
              </a>
            </v-card>
          </v-expand-transition>
        </div>
        <div class="virtual-gallery-2020-container">
          <h6 class="pointer text-h6 my-4" @click="toggleProductClip()">
            Product Clip 2020
            <v-icon color="black" v-show="!productClip"
              >mdi-menu-down</v-icon
            >
            <v-icon color="black" v-show="productClip"
              >mdi-menu-up</v-icon
            >
          </h6>
          <v-expand-transition>
            <v-card v-show="productClip" width="100%">
              <video
                controls
                :src="'/videos/product-preview.mp4'"
                :poster="require('@/assets/products/product-clip.png')"
              ></video>
            </v-card>
          </v-expand-transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Live",
  components: {
    // Carousel,
  },
  data() {
    const windowSize = screen.width;
    const isMobile = windowSize < 769;
    return {
      windowSize,
      isMobile,
      expandVirtualGallery: false,
      productClip: false,
    };
  },
  watch: {
    isMobile(newWidth) {
      this.isMobile = newWidth < 769;
      this.windowSize = newWidth;
    },
  },
  methods: {
    toggleVirtualGallery() {
      this.expandVirtualGallery = !this.expandVirtualGallery;
    },
    toggleProductClip: function () {
      this.productClip = !this.productClip;
    },
  },
};
</script>
<style lang="scss">
#vdo-list {
  .scroll {
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .box {
    cursor: pointer;
    background-color: black;
    padding: 0.25rem;
  }
}
.ma {
  margin: auto;
}
.live-actions {
  float: right;
}
.live-vdo {
  width: 100%;
  // min-height: 500px;

  @media screen and (max-width: 768px) {
    min-height: 400px;
  }

  @media screen and (max-width: 426px) {
    min-height: 250px;
  }
}
.delete-mt {
  .col-center {
    width: 100%;

    @media screen and (max-width: 2560px) {
      width: 65%;
    }
    @media screen and (max-width: 1440px) {
      width: 69%;
    }

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
  .box {
    width: 90%;
  }

  .two {
    margin: auto;
    width: 100%;
  }

  .three {
    float: right;
    img {
      @media screen and (max-width: 768px) {
        padding-bottom: 80px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin-top: -90px;
    .box {
      width: 100%;
    }
  }
}

.pull-down {
  display: flex;
  align-items: flex-end;
}
.home {
  margin-bottom: -100px;
}

.live-container {
  min-height: 30%;
  @media screen and (min-width: 769px) {
    padding: 4rem;
  }
}
.bottom-content {
  background-size: cover;
  background-repeat: no-repeat;
  @media screen and (min-width: 769px) {
    padding: 1.5rem;
  }

  @media screen and (max-width: 769px) {
    margin-top: 60px;
  }

  .package img {
    @media screen and (max-width: 426px) {
      padding-bottom: 85px;
    }

    @media screen and (max-width: 769px) {
      padding-bottom: 90px;
    }
  }
}
.lazboy-conf-title {
  font-family: "Helvetica Neue LT W05 65 Medium";
  color: #0069bf;
}
.pointer {
  cursor: pointer;
}
.bg-blue {
  // background-color: #002c56;
  padding: 16px;
}
</style>
